/* eslint-disable max-len */
import styled from 'styled-components';

export type ButtonProps = {
  $backgroundColor?: string;
  $textColor?: string;
  $primary?: boolean;
  $transparent?: boolean;
  $danger?: boolean;
};

export const buttonHeight = 32;

const Button = styled.button<ButtonProps>`
  all: unset;
  box-sizing: border-box;
  min-height: ${buttonHeight}px;
  font-weight: bold;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ $primary, $textColor }) => ($primary ? 'white' : $textColor)};
  background-color: ${({ $backgroundColor, $primary, theme }) => ($backgroundColor || ($primary ? theme.colors.primary : theme.colors.foreground))};
  padding: 4px 10px;
  cursor: pointer;
  transition: background 200ms ease;
  border: transparent solid thin;
  @media (hover: hover) {
    &:hover {
      text-decoration: none;
      background-color: ${({ $primary, theme }) => ($primary ? theme.colors.primaryDark : theme.colors.opacity)};
    }
  }
  ${({ disabled, theme }) => disabled && `
    cursor: default;
    background-color: ${theme.colors.foreground};
    color: ${theme.colors.textLight};
    @media (hover: hover) {
      &:hover {
        background-color: ${theme.colors.foreground};
      }
    }
  `}
  ${({ $transparent }) => $transparent && `
    background-color: transparent;
  `}
  ${({ $danger, theme }) => $danger && `
    background-color: ${theme.colors.alert};
    color: white;
    @media (hover: hover) {
      &:hover {
        text-decoration: none;
        background-color: ${theme.colors.alert};
      }
    }
  `}
`;

export default Button;
