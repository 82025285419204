/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';
import { TiWeatherSunny, TiWeatherNight } from 'react-icons/ti';
import styled from 'styled-components';
import { Theme, useThemeProvider } from '~/contexts/Theme';

const ThemeToggleContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  border: ${({ theme }) => theme.colors.borderXLight} solid thin;
  height: 34px;
  padding: 6px 10px;
  border-radius: 20px;
  cursor: pointer;
  transition: border-color 200ms ease;
  @media (hover: hover) {
    &:hover {
      border-color: ${({ theme }) => theme.colors.borderXXLight};
    }
  }
`;

const ThemeIcon = styled.div.attrs({
  size: 20,
})<{ $active: boolean; }>`
  fill: ${({ $active, theme }) => ($active ? theme.colors.text : theme.colors.textXLight)};
  &:first-child {
    margin-right: 6px;
  }
`;

const ThemeToggle = () => {
  const { theme, toggleTheme } = useThemeProvider();
  return (
    <ThemeToggleContainer onClick={ toggleTheme }>
      <ThemeIcon as={ TiWeatherSunny } $active={ theme === Theme.Light } />
      <ThemeIcon as={ TiWeatherNight } $active={ theme === Theme.Dark } />
    </ThemeToggleContainer>
  );
};

export default ThemeToggle;
