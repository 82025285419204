import useTheme from '~/hooks/useTheme';

export const logoRatio = 30 / 84;
export const logoHeight = 20;

const Logo: React.FC<{
  className?: string;
  stroke?: string;
  backgroundColor?: string;
  width?: number;
  height?: number;
}> = (props) => {
  const theme = useTheme();
  const {
    className,
    stroke = theme.colors.text,
    backgroundColor = theme.colors.background,
    width = logoHeight / logoRatio,
    height = logoHeight,
  } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -0.5 14 5"
      shapeRendering="crispEdges"
      width={ width }
      height={ height }
      style={ { backgroundColor } }
      className={ className }
    >
      <path stroke={ stroke } d="M0 0h1M0 1h1M6 2h1M3 4h1" />
      <path stroke={ stroke } d="M3 0h1M5 0h1M1 2h1M3 2h1M0 4h1" />
      <path stroke={ stroke } d="M6 0h1M11 0h1M7 2h1M3 3h1M5 3h1" />
      <path stroke={ stroke } d="M7 0h1M10 1h1M0 3h1M8 4h1" />
      <path stroke={ stroke } d="M8 0h1M10 0h1M2 2h1M8 2h1M10 2h1M12 2h1M5 4h1M10 4h1" />
      <path stroke={ stroke } d="M12 0h2M5 2h1M11 2h1M10 3h1" />
      <path stroke={ stroke } d="M3 1h1M0 2h1M13 2h1" />
      <path stroke={ stroke } d="M8 3h1" />
    </svg>
  );
};

export default Logo;
