/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable indent */
import React from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
// import useFavorite from '~/hooks/useFavorite';
import uniqBy from 'lodash/uniqBy';
import useLocalStorage from '~/hooks/useLocalStorage';
import useBlockedWallets from '~/hooks/useBlockedWallets';
import { INDEXER_URLS } from '~/utils/request';

export type Currency = 'eth' | 'usd' | 'eur' | null;
export type Layout = 'gallery' | 'regular' | 'advanced';

type DataProviderContextProps = {
  blockedWallets: string[];
  layout: Layout;
  setLayout: React.Dispatch<React.SetStateAction<Layout>>;
  currency: Currency;
  setCurrency: React.Dispatch<React.SetStateAction<Currency>>;
  ethPrice: number;
  usdPrice: number;
  price: number;
  favorites: number[];
  followed: string[];
  setFavorites: (f: number[]) => number[];
  setFollowed: (f: string[]) => string[];
  indexer: string;
  setIndexer: React.Dispatch<React.SetStateAction<string>>;
  indexerError: boolean;
  setIndexerError: React.Dispatch<React.SetStateAction<boolean>>;
  lastReadNotifications: string;
  setLastReadNotifications: (f: string) => string;
};

const defaultDataProviderContext = {
  blockedWallets: [],
  layout: 'regular' as Layout,
  setLayout: () => null,
  currency: null,
  setCurrency: () => null,
  ethPrice: 0,
  usdPrice: 0,
  price: 0,
  isFavorite: () => false,
  favorite: () => null,
  unfavorite: () => null,
  favorites: [],
  followed: [],
  setFavorites: () => null,
  setFollowed: () => null,
  indexer: null,
  setIndexer: () => null,
  indexerError: null,
  setIndexerError: () => null,
  lastReadNotifications: null,
  setLastReadNotifications: () => null,
};

const DataProviderContext = React.createContext<DataProviderContextProps>(defaultDataProviderContext);

const useEthPrice = () => useQuery(
  ['eth'],
  async () => {
    const { data } = await axios.get('https://api.coingecko.com/api/v3/simple/price', {
      params: {
        ids: 'tezos',
        vs_currencies: 'eth',
      },
    });
    return +data?.tezos?.eth;
  },
);

const useUsdPrice = () => useQuery(
  ['usd'],
  async () => {
    const { data } = await axios.get('https://api.coingecko.com/api/v3/simple/price', {
      params: {
        ids: 'tezos',
        vs_currencies: 'usd',
      },
    });
    return +data?.tezos?.usd;
  },
);

const useEurPrice = () => useQuery(
  ['eur'],
  async () => {
    const { data } = await axios.get('https://api.coingecko.com/api/v3/simple/price', {
      params: {
        ids: 'tezos',
        vs_currencies: 'eur',
      },
    });
    return +data?.tezos?.eur;
  },
);

export const useFavorites = () => {
  const [favorites_ = [], setFavorites] = useLocalStorage('favorites', []);
  const favorites = uniqBy(favorites_.map((f) => +f), (f) => f) as number[];
  return [favorites, setFavorites];
};

export const useFollowed = () => {
  const [followed_ = [], setFollowed] = useLocalStorage('followed', []);
  const followed = uniqBy(followed_.map((f) => String(f)), (f) => f) as string[];
  return [followed, setFollowed];
};

export const DataProvider: React.FC = ({ children }) => {
  const { data: blockedWallets = [] } = useBlockedWallets();
  const [lastReadNotifications, setLastReadNotifications] = useLocalStorage('lastReadNotifications', null);
  const [favorites, setFavorites] = useFavorites();
  const [followed, setFollowed] = useFollowed();
  const [currency, setCurrency] = useLocalStorage('currency', null);
  const [indexer, setIndexer] = useLocalStorage('indexer', INDEXER_URLS[0]);
  const [indexerError, setIndexerError] = React.useState(false);
  const [layout, setLayout] = useLocalStorage('layout', 'regular');
  const { data: ethPrice } = useEthPrice();
  const { data: usdPrice } = useUsdPrice();
  const { data: eurPrice } = useEurPrice();
  const price = {
    eth: ethPrice,
    usd: usdPrice,
    eur: eurPrice,
  }[currency];
  const value = {
    blockedWallets,
    layout,
    setLayout,
    currency,
    setCurrency,
    ethPrice,
    usdPrice,
    eurPrice,
    price,
    favorites,
    setFavorites,
    followed,
    setFollowed,
    indexer: INDEXER_URLS.includes(indexer) ? indexer : INDEXER_URLS[0],
    setIndexer,
    indexerError,
    setIndexerError,
    lastReadNotifications,
    setLastReadNotifications,
  };
  return (
    <DataProviderContext.Provider value={ value }>
      { children }
    </DataProviderContext.Provider>
  );
};

export const useDataContext = () => React.useContext(DataProviderContext);
