import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  createContext,
  ReactNode,
  SetStateAction,
  Dispatch,
  FC,
} from 'react';
import { ThemeProvider } from 'styled-components';
import { isBrowser } from '~/utils';
import { themes, Theme } from '~/styles/themes';

export { Theme } from '~/styles/themes';

// const isBrowserDarkMode: boolean = isBrowser && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
// const defaultTheme: Theme = isBrowserDarkMode ? Theme.Dark : Theme.Light;

const defaultTheme: Theme = Theme.Dark;

type ThemeContext = {
  theme: Theme;
  setTheme: Dispatch<SetStateAction<Theme>>;
  toggleTheme: () => void;
};

const localStorageKey = 'appTheme';
const localStorage = global.localStorage as Storage;
const initialLocalStorageValue = isBrowser && localStorage.getItem(localStorageKey) as Theme;
const initialTheme = initialLocalStorageValue || defaultTheme;

const defaultThemeProviderContext = {
  theme: initialTheme,
  setTheme: () => {},
  toggleTheme: () => {},
};

const ThemeProviderContext = createContext<ThemeContext | undefined>(defaultThemeProviderContext);

type ThemeProviderProps = {
  children: ReactNode;
};

// eslint-disable-next-line import/no-unused-modules
export const AppThemeProvider: FC<ThemeProviderProps> = ({ children }) => {
  const [theme, setTheme] = useState<Theme>(initialTheme);
  const toggleTheme = () => setTheme(theme === Theme.Dark ? Theme.Light : Theme.Dark);
  const themeData = useMemo(() => themes[theme] || themes[defaultTheme], [theme]);
  useEffect(() => {
    if (theme) {
      localStorage.setItem(localStorageKey, theme);
    }
  }, [theme]);
  const [mounted, setMounted] = React.useState(false);
  React.useEffect(() => {
    setMounted(true);
  }, []);
  const body = (
    <ThemeProviderContext.Provider value={ { theme, setTheme, toggleTheme } }>
      <ThemeProvider theme={ themeData }>
        { children }
      </ThemeProvider>
    </ThemeProviderContext.Provider>
  );

  // prevents ssr flash for mismatched dark mode
  if (!mounted) {
    return <div style={ { visibility: 'hidden' } }>{body}</div>;
  }
  return body;
};

// eslint-disable-next-line import/no-unused-modules
export const useThemeProvider = () => useContext(ThemeProviderContext);

export const useIsDarkMode = () => {
  const { theme } = useThemeProvider();
  const isDarkMode = theme === Theme.Dark;
  return isDarkMode;
};
