import { useRouter } from 'next/router';
import React from 'react';
import styled from 'styled-components';
import { RiSearchLine } from 'react-icons/ri';
import { isTezosAddress } from '~/utils';
import Input from './Input';
import { buttonHeight } from './Button';
import useTheme from '~/hooks/useTheme';

const Form = styled.form`
  flex: 1;
  width: 100%;
  position: relative;
  display: inline-flex;
  flex-direction: row;
  max-width: 400px;
  width: auto;
`;

const StyledInput = styled(Input)`
  padding-right: ${buttonHeight + 15}px;
  align-items: flex-end;
`;

const SearchButton = styled.button.attrs({
  type: 'submit',
})`
  all: unset;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: ${buttonHeight}px;
  height: ${buttonHeight}px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 200ms ease;
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.opacity};
    }
  }

`;

const SearchInput = () => {
  const inputRef = React.useRef<HTMLInputElement>();
  const router = useRouter();
  const { q, section = '' } = router.query as { q: string; section: string };
  const [value, setValue] = React.useState<string>('');
  const handleChange = (e) => setValue(e.target.value);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (isTezosAddress(value)) {
      router.push(`/tz/${value}`);
    } else {
      router.push(`/search/${section}${value ? `?q=${encodeURIComponent(value)}` : ''}`);
    }
    setValue('');
    inputRef.current.blur();
  };
  React.useEffect(() => {
    setValue(q || '');
  }, [q]);
  const theme = useTheme();
  return (
    <Form onSubmit={ handleSubmit }>
      <StyledInput
        ref={ inputRef }
        type="search"
        placeholder="Search objkts or artists"
        value={ value }
        onChange={ handleChange }
        spellCheck={ false }
        autoCorrect="none"
        autoCapitalize="none"
      />
      <SearchButton>
        <RiSearchLine color={ theme.colors.textLight } />
      </SearchButton>
    </Form>
  );
};

export default SearchInput;
