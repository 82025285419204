/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'graphql-request';
import { RequestDocument } from 'graphql-request/dist/types';
import { useDataContext } from '~/contexts/Data';

export const INDEXER_URLS = [
  // 'https://api.hicdex.com/v1/graphql',
  // 'https://hicdex.teztools.net/v1/graphql',
  // 'https://hdapi.teztools.io/v1/graphql',
  // 'https://hdnet.teztools.net/v1/graphql',
  // 'https://api.teia.rocks/v1/graphql',
  'https://api-v5.teia.rocks/v1/graphql',
];

export const useGqlClient = () => {
  const { indexer } = useDataContext();
  async function gqlClient<T>(
    query: RequestDocument,
    params?: any,
  ) {
    return request<T>(
      indexer,
      query,
      params,
    );
  }
  return gqlClient;
};

async function requestClient<T>(
  query: RequestDocument,
  params?: any,
) {
  let res;
  for (let i = 0; i < INDEXER_URLS.length; i += 1) {
    const url = INDEXER_URLS[i];
    try {
      res = await request<T>(
        url,
        query,
        params,
      );
      if (res) break;
    } catch (err) {
      console.error('Indexer fail.', { url, err });
    }
  }
  return res || {};
}

export default requestClient;
