import styled from 'styled-components';
import { buttonHeight } from './Button';

const StyledSelect = styled.select`
  all: unset;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.foreground};
  height: ${buttonHeight}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
  padding-right: 28px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  option {
    background: ${({ theme }) => theme.colors.foreground};
    color: ${({ theme }) => theme.colors.text};
  }
  &::after {
    content: "";
  }
`;

const SelectWrapper = styled.div`
  display: inline-table;
  position: relative;
`;

const SelectArrow = styled.div`
  padding: 12px 8px;
  position: absolute;
  right: 2px;
  top: -5px;
  text-align: center;
  pointer-events: none;
  font-size: ${({ theme }) => theme.fontSize.regular};
  color: ${({ theme }) => theme.colors.textLight};
`;

const Select = (props) => (
  <SelectWrapper>
    <StyledSelect { ...props } />
    <SelectArrow>▼</SelectArrow>
  </SelectWrapper>
);

export default Select;
