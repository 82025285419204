/* eslint-disable */
import React from 'react';
import dynamic from 'next/dynamic';
import { BeaconWallet } from '@taquito/beacon-wallet'
import { TezosToolkit, OpKind, MichelsonMap, TransactionWalletOperation, WalletOperationBatch } from '@taquito/taquito'
import { Parser } from "@taquito/michel-codec";
import { Schema } from "@taquito/michelson-encoder";
import axios from 'axios';
import eztz from 'eztz-lib';
import { BatchWalletOperation } from '@taquito/taquito/dist/types/wallet/batch-operation';
import { Objkt } from '~/types';

export type Feedback = {
    visible: boolean;
    message: string;
    progress: boolean;
    confirm: boolean;
    confirmCallback: () => void;
};

export type SwapInput = {
    swapId?: number;
    owner: string;
    creator: string;
    objktId: number;
    objktRoyalties: number;
    ops: {
        quantity: number;
        price: number;
    }[];
};

export type TransferInput = {
    address: string;
    objktId: number;
    quantity: number;
};

export type BurnInput = Omit<TransferInput, 'address'>;

export type MintInput = {
    address: string;
    editions: number;
    cid: string;
    royalties: number;
};

export type HicetnuncContextProps = {
    commission: number;
    setCommission: (number) => void;
    donate: (number) => Promise<TransactionWalletOperation>;
    hicaf: string;
    hDAO: string;
    subjkt: string;
    v1: string;
    unregistry: string;
    v2: string;
    objkts: string;
    hDAO_curation: string;
    hDAO_marketplace: string;
    subjktInfo: any;
    setSubjktInfo: (subjkt: any) => void;
    collect_hdao: (from: string, swap_id: string, token_address: string, token_id: string) => void;
    swap_hDAO: (from: string, royalties: number, token_per_objkt: number, objkt_id: string, creator: any, objkt_amount: number) => void;
    collectv2: (swap_id: string, xtz_amount: number) => void,
    swapv2: (from: string, royalties: number, xtz_per_objkt: number, objkt_id: string, creator: any, objkt_amount: number) => void,
    batch_cancel: (ids: number[]) => Promise<WalletOperationBatch>;
    fullscreen: boolean;
    setFullscreen: (fullscreen: boolean) => void;
    theme: string;
    setTheme: (theme: string) => void;
    feedback: Feedback;
    setFeedback: (feedback: Feedback) => void;
    pathname: string;
    address: string;
    op: any;
    contract: string;
    setAddress: (address: string) => void;
    setAuth: (address: string) => void;
    updateLs: (key: string, value: any) => void;
    getLs: (key: string) => string;
    getAuth: () => string;
    client: any;
    setClient: (client: any) => void;
    dAppClient: () => void;
    proxyAddress: string;
    originatedContract: string;
    setProxyAddress: (proxyAddress: string) => void;
    getProxy: () => string;
    objkt: string;
    mint: (o: MintInput) => Promise<TransactionWalletOperation>;
    batchMint: (o: MintInput[]) => Promise<BatchWalletOperation>;
    collect: (o: { swapId: number; price: number; }[], tip?: number) => Promise<BatchWalletOperation>;
    swap: (o: SwapInput) => Promise<BatchWalletOperation>;
    editSwap: (oldSwapId: number, newSwapData: SwapInput) => Promise<BatchWalletOperation>;
    curate: (objkt_id: string) => void;
    claim_hDAO: (hDAO_amount: number, objkt_id: string) => void;
    transfer: (payload: TransferInput) => Promise<TransactionWalletOperation>;
    burn: (payload: BurnInput) => Promise<TransactionWalletOperation>;
    batchTransfer: (payload: TransferInput[]) => Promise<BatchWalletOperation>;
    batchBurn: (payload: BurnInput[]) => Promise<BatchWalletOperation>;
    cancelv1: (swap_id: string) => void;
    cancel: (swap_id: number) => Promise<TransactionWalletOperation>;
    batchSwap: (o: SwapInput[]) => Promise<BatchWalletOperation>;
    batchEdit: (swaps: SwapInput[]) => Promise<BatchWalletOperation>;
    batchCancel: (swap_ids: number[]) => Promise<BatchWalletOperation>;
    signStr: (payload: any) => void;
    signIn: () => void;
    registry: (alias: any, metadata: any) => void;
    hDAO_update_operators: (address: string) => void;
    unregister: () => void;
    load: boolean;
    loading: () => void;
    /* taquito */
    Tezos: any;
    wallet: any;
    acc: any;
    updateMessage: (message: string) => void;
    setAccount: () => void;
    syncTaquito: () => void;
    disconnect: () => void;
    operationRequest: (obj: any) => void;
    timeout: (delay: number) => void;
    signPayload: (obj: any) => void;
    balance: number;
    refreshBalance: (address: string) => void;
    collapsed: boolean;
    toogleNavbar: () => void;
    setMenu: (collapsed: boolean) => void;
    getStyle: (style: any) => any;
    lastPath: string;
    setPath: (path: string) => void;
    title: string;
    setTitle: (title: string) => void;
    hDAO_vote: string;
    proxyFactoryAddress: string;
    mockProxy: () => void;
    originateProxy: (administratorAddress: string, participantData: any) => void;
};

const defaultHicetnuncContext = {};

// @ts-ignore
const HicetnuncContext = React.createContext<HicetnuncContextProps>(defaultHicetnuncContext);

export default HicetnuncContext;
