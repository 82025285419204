import React from 'react';
import ReactDOM from 'react-dom';
import styled, { createGlobalStyle, css } from 'styled-components';
import { AnimatePresence, motion, MotionStyle } from 'framer-motion';
import { RiCloseLine } from 'react-icons/ri';
import { media } from '~/styles/media';
import useTheme from '~/hooks/useTheme';

export const Portal = ({ children }) => (process.browser ? ReactDOM.createPortal(children, document.body) : null);
export const easing = [0.175, 0.85, 0.42, 0.96];

const backdropVariant = {
  initial: {
    opacity: 0,
    backdropFilter: 'blur(0)',
  },
  isOpen: {
    opacity: 1,
    backdropFilter: 'blur(4px)',
  },
  exit: {
    opacity: 0,
    backdropFilter: 'blur(0)',
  },
};

const windowVariant = {
  initial: { scale: 0.9 },
  isOpen: { scale: 1 },
  exit: { scale: 0.9 },
};

const ModalGlobalStyle = createGlobalStyle`
  body {
    /*
    overflow-y: hidden !important;
    */
  }
`;

const ModalBackdrop = styled(motion.div)<{
  isClicked?: boolean;
}>`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  transition: transform 150ms ease;
  transform: scale(${({ isClicked }) => (isClicked ? 1.01 : 1)});
  user-select: none;
  padding: 10px;
  &:focus {
    outline: 0;
  }
  ${media.mobile`
    min-height: -webkit-fill-available;
  `}
`;

const ModalWindow = styled(motion.div)<{ width: number; fullscreen?: boolean; }>`
  z-index: 10000;
  background-color: ${({ theme }) => theme.colors.background};
  margin: ${({ fullscreen }) => (fullscreen ? 0 : 'auto')};
  width: 100%;
  max-width: ${({ width = 550 }) => width}px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 0;
  border-color: ${({ theme }) => theme.colors.borderXXLight};
  border-width: 1px;
  border-style: solid;
  user-select: text;
  ${media.mobile`
    max-width: 9999px;
  `}
`;

export const ModalHeader = styled.header`
  padding: 15px 15px 15px 20px;
  border-bottom: ${({ theme }) => theme.colors.borderLight} solid 1px;
  border-color: ${({ theme }) => theme.colors.borderXLight};
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  h2 {
    margin: 0;
    margin-left: 2px;
    font-size: ${({ theme }) => theme.fontSize.large};
  }
`;

export const ModalTitle = styled.h2`
  flex: 1;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;  
`;

export const ModalCloseButtonContainer = styled.button.attrs({ type: 'button' })`
  all: unset;
  height: 100%;
  cursor: pointer;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  svg {
    color: ${({ theme }) => theme.colors.textLight};
  }
  @media (hover: hover) {
    &:hover {
      svg {
        color: ${({ theme }) => theme.colors.text};
      }
    }
  }
`;

export const ModalCloseButton: React.FC<{
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  color?: string;
  size?: number;
  className?: string;
  children?: React.ReactNode;
}> = ({
  onClick,
  color,
  size = 24,
  className,
  children,
}) => {
  const theme = useTheme();
  const color2 = color || theme.colors.textLight;
  return (
    <ModalCloseButtonContainer onClick={ onClick } className={ className }>
      { children || <RiCloseLine color={ color2 } size={ size } /> }
    </ModalCloseButtonContainer>
  );
};

export const FixedModalButtonStyle = css`
  position: fixed;
  top: 0;
  right: 0;
  height: 50px;
  width: 50px;
  transition: background-color 200ms ease;
  background-color: ${({ theme }) => theme.colors.foreground};
  &:hover {
    background-color: ${({ theme }) => theme.colors.opacity};
  }
  animation: 600ms fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
  @keyframes fadeIn {
    99% {
      visibility: hidden;
    }
    100% {
      visibility: visible;
    }
  }
`;

export const FixedModalCloseButton = styled(ModalCloseButton)`
  ${FixedModalButtonStyle}
`;

export const ModalBody = styled.div`
  flex: 1;
  overflow-y: scroll;
  /*
  overflow-x: hidden;
  */
  white-space: pre-line;
  word-break: break-word;
  padding: 20px;
  position: relative;
  ${media.mobile`
    height: auto;
    max-height: none;
  `}
`;

export const ModalInput = styled.input`
  all: unset;
  text-align: left;
  padding: 10px 15px;
  flex: 1;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  transition: border 200ms ease, background 200ms ease;
  border: transparent solid 1px;
  border-color: ${({ theme }) => theme.colors.border};
  background-color: ${({ theme }) => theme.colors.opacity};
  &:focus {
    border-color: ${({ theme }) => theme.colors.borderDark};
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: ${({ theme }) => theme.colors.borderXLight} solid thin;
  padding: 15px 15px;
`;

export type ModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  children: React.ReactChild | React.ReactChild[];
  width?: number;
  fullscreen?: boolean;
  backdropStyle?: MotionStyle;
  windowStyle?: MotionStyle;
  keyPressEvents?: {
    [k: number]: () => void;
  };
};

const Modal: React.FC<ModalProps> = ({
  isOpen,
  handleClose,
  children,
  width,
  fullscreen,
  keyPressEvents,
  backdropStyle,
  windowStyle,
}) => {
  const backdropRef = React.useRef<HTMLDivElement>();
  const [backdropClicked, setBackdropClicked] = React.useState<boolean>(false);
  const handleClickWindow = (e) => {
    e.stopPropagation();
  };
  React.useEffect(() => {
    if (isOpen) {
      backdropRef.current.focus();
    }
  }, [isOpen]);
  const handlePressEscape = (e) => {
    e.stopPropagation();
    if (e.type === 'click') return handleClose();
    const baseEvents = { 27: handleClose };
    const events = { ...baseEvents, ...(keyPressEvents || {}) };
    const fn = events[e.keyCode || e.which];
    if (fn) fn();
    return null;
  };
  return (
    <Portal>
      <AnimatePresence>
        {
          isOpen ? (
            <>
              <ModalGlobalStyle />
              <ModalBackdrop
                tabIndex={ 0 }
                ref={ backdropRef }
                key="backdrop"
                initial="initial"
                animate="isOpen"
                exit="exit"
                variants={ backdropVariant }
                transition={ { duration: 0.1 } }
                onKeyUp={ handlePressEscape }
                onClick={ handlePressEscape }
                onMouseDown={ () => setBackdropClicked(true) }
                onMouseUp={ () => setBackdropClicked(false) }
                onPointerDown={ () => setBackdropClicked(true) }
                onPointerUp={ () => setBackdropClicked(false) }
                isClicked={ backdropClicked }
                style={ backdropStyle }
              >
                <ModalWindow
                  key="modal"
                  initial="initial"
                  animate="isOpen"
                  exit="exit"
                  variants={ windowVariant }
                  transition={ { duration: 0.3, ease: easing } }
                  onClick={ handleClickWindow }
                  onMouseDown={ (e) => e.stopPropagation() }
                  onMouseUp={ (e) => e.stopPropagation() }
                  onPointerDown={ (e) => e.stopPropagation() }
                  onPointerUp={ (e) => e.stopPropagation() }
                  width={ width }
                  fullscreen={ fullscreen }
                  style={ windowStyle }
                >
                  {children}
                </ModalWindow>
              </ModalBackdrop>
            </>
          ) : null
        }
      </AnimatePresence>
    </Portal>
  );
};

export default Modal;
