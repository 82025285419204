import { useDataContext } from '~/contexts/Data';
import Select from './Select';

const CurrencySelect = () => {
  const { currency, setCurrency } = useDataContext();
  return (
    <Select value={ currency } onChange={ (e) => setCurrency(e.target.value) }>
      {
        [
          {
            label: '- No -',
            value: '',
          },
          {
            label: 'ETH',
            value: 'eth',
          },
          {
            label: 'EUR',
            value: 'eur',
          },
          {
            label: 'USD',
            value: 'usd',
          },
        ].map(({ label, value }) => (
          <option key={ `currency.${value}` } value={ value }>
            { label }
          </option>
        ))
      }
    </Select>
  );
};

export default CurrencySelect;
