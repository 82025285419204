/* eslint-disable max-len */
import styled from 'styled-components';
import Link from 'next/link';
import React from 'react';
import dynamic from 'next/dynamic';
import { RiDiscordLine, RiTwitterLine } from 'react-icons/ri';
import SearchInput from './SearchInput';
import AboutModal from './AboutModal';
import { audioPlayerHeight, DISCORD_URL, TWITTER_URL } from '~/utils/const';
import FlexRow from './FlexRow';
import Logo, { logoRatio } from './Logo';

import ExternalLink from './ExternalLink';
import { colors } from '~/styles/themes';
import Emoji from './Emoji';
import { useDataContext } from '~/contexts/Data';
import SettingsModal from './SettingsModal';
// import TextNeon from './TextNeon';

const SettingsButton = dynamic(() => import('./SettingsButton'), {
  ssr: false,
});

const announcementTextHeight = 0;
const AnnouncementBox = styled.div`
  padding: 0;
  margin-bottom: 15px;
  border: 0;
  text-align: left;
  background-color: ${({ theme }) => theme.colors.opacity};
  padding: 8px 10px;
  color: ${({ theme }) => theme.colors.neon};
  font-weight: bold;
  display: table;
`;

export const appWidth = 2400;

export const PageColumn = styled.div`
  width: 100%;
  max-width: ${appWidth}px;
  margin: auto;
  padding: 0;
`;

export const LayoutContainer = styled(PageColumn)`
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  min-height: 100vh;
`;

export const ukraineFlagHeight = 10;

export const UkraineFlag = styled.div`
  height: ${ukraineFlagHeight}px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background: linear-gradient(180deg, #005BBB 50%, #FFD500 50%);
`;

export const headerMenuHeight = 55;
// export const headerHeight = announcementTextHeight + headerMenuHeight + ukraineFlagHeight;
export const headerHeight = announcementTextHeight + headerMenuHeight;

const HeaderContainer = styled.nav`
  position: fixed;
  /*
  top: ${ukraineFlagHeight}px;
  */
  left: 0;
  right: 0;
  z-index: 2;
  width: 100%;
  max-width: 100vw;
  border-bottom: ${({ theme }) => theme.colors.borderXLight} solid thin;
  background-color: ${({ theme }) => theme.colors.background};
  /*
  background-color: ${({ theme }) => theme.colors.backgroundTranslucent};
  backdrop-filter: blur(20px);
  */
  transform: translateZ(0px);
  margin-bottom: -20px;
`;

const HeaderContent = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${headerMenuHeight}px;
`;

const HeaderPageColumn = styled(PageColumn)`
  padding: 0 15px;
`;

const LogoLink = styled.a`
  display: contents;
`;

const StyledLogo = styled(Logo)`
  margin-right: 15px;
  width: ${24 / logoRatio}px;
  height: 24px;
  path {
    transition: stroke 200ms ease;
  }
  &:hover {
    path {
      stroke: ${({ theme }) => theme.colors.textLight};
    }
  }
`;

export const Header = () => (
  <HeaderContainer>
    <HeaderContent>
      <HeaderPageColumn>
        <FlexRow>
          <Link href="/" passHref>
            <LogoLink>
              <StyledLogo />
            </LogoLink>
          </Link>
          <SearchInput />
          <SettingsButton />
        </FlexRow>
      </HeaderPageColumn>
    </HeaderContent>
  </HeaderContainer>
);

const Footer = styled.footer`
  padding: 15px 20px;
  margin: 0 -15px;
  margin-top: 15px;
  border-top: ${({ theme }) => theme.colors.borderXLight} solid thin;
`;

export const LayoutBody = styled.div`
  flex: 1;
  margin-top: ${headerHeight + 15}px;
  padding: 15px 0;
  /*
  margin-bottom: ${audioPlayerHeight}px;
  */
`;

const LinkIcon = styled.div`
  margin: 0 6px;
  position: relative;
  top: 3px;
  transition: opacity 200ms ease;
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
`;

export const Announcement = () => (
  <AnnouncementBox>
    <ExternalLink href="https://twitter.com/HIC_AF/status/1462907073667162113?s=20">
      <Emoji v="🔥" style={ { marginRight: 5 } } />
      We will be having our 2nd community call this Thursday at 5pm UTC on Twitter Spaces. Join us to chat with fellow creators & collectors. Talk soon!
    </ExternalLink>
  </AnnouncementBox>
);

export const IndexerError = () => {
  const { indexerError } = useDataContext();
  const [visible, setVisible] = React.useState<boolean>(false);
  if (indexerError) {
    return (
      <>
        <AnnouncementBox as="a" onClick={ () => setVisible(true) }>
          The indexer you are using may have an issue preventing you from seeing the latest data. Try changing it!
        </AnnouncementBox>
        <SettingsModal isOpen={ visible } handleClose={ () => setVisible(false) } />
      </>
    );
  }
  return null;
};

const Layout: React.FC<React.PropsWithChildren<{ hideFooter?: boolean; }>> = ({ children, hideFooter }) => (
  <LayoutContainer>
    {/*
    <ExternalLink href="https://twitter.com/Ukraine/status/1497594592438497282?s=20&t=L1VqWR2DIcKlHuT7hsdMLg">
      <UkraineFlag />
    </ExternalLink>
    */}
    <Header />
    <LayoutBody>
      {/* <IndexerError /> */}
      {children}
    </LayoutBody>
    {
      hideFooter ? null : (
        <Footer>
          <FlexRow style={ { justifyContent: 'flex-end' } }>
            <ExternalLink href={ DISCORD_URL }>
              <LinkIcon as={ RiDiscordLine } color={ colors.discord } size={ 20 } />
            </ExternalLink>
            <ExternalLink href={ TWITTER_URL }>
              <LinkIcon as={ RiTwitterLine } color={ colors.twitter } size={ 20 } />
            </ExternalLink>
            <div style={ { marginLeft: 10 } }>
              <AboutButton />
            </div>
          </FlexRow>
        </Footer>
      )
    }
  </LayoutContainer>
);

export default Layout;

const AboutButton = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  return (
    <>
      <a role="button" tabIndex={ 0 } onClick={ () => setIsOpen(true) } onKeyUp={ () => null }>
        ABOUT HIC.AF
      </a>
      <AboutModal isOpen={ isOpen } handleClose={ () => setIsOpen(false) } />
    </>
  );
};
