import styled, { css } from 'styled-components';
import { buttonHeight } from './Button';

export const inputCss = css`
  all: unset;
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.foreground};
  padding: 0 10px;
  width: 100%;
  cursor: auto;
  box-sizing: border-box;
  height: ${buttonHeight}px;
  border-radius: 0px;
  text-align: left;
  @supports (-webkit-touch-callout: none) {
    font-size: 16px;
  }
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
`;

const Input = styled.input`
  ${inputCss}
`;

export default Input;
