export enum Theme {
  Light = 'light',
  Dark = 'dark',
}

export const colors = {
  primary: 'rgb(60, 64, 198)',
  primaryDark: 'rgb(30, 33, 135)',
  primaryLight: '#3C91E6',
  secondary: '#27ae60',
  success: '#27ae60',
  alert: '#e74c3c',
  twitter: '#1DA1F2',
  discord: '#5865F2',
  neon: '#bc13fe',
  instagram: '#F56040',
  telegram: '#0088cc',
  whatsapp: '#25D366',
  white: '#FFFFFF',
  profit: '#00bf36',
  loss: '#f03434',
};

const fontSize = {
  small: '0.7rem',
  regular: '0.8rem',
  regularMobile: '0.9rem',
  large: '0.9rem',
  xlarge: '1rem',
};

const lightTheme = {
  fontSize,
  appWidth: 1200,
  padding: 15,
  borderRadius: 9,
  progressBarColor: '#f2703f',
  colors: {
    ...colors,
    inverse: 'black',
    foreground: 'rgb(235, 235, 235)',
    foregroundLight: 'rgb(245, 245, 245)',
    background: 'rgb(255, 255, 255)',
    backgroundTranslucent: 'rgba(255, 255, 255, 0.7)',
    foregroundHover: 'rgb(250, 250, 250)',
    tagActive: 'black',
    tagActiveText: 'white',
    text: 'black',
    textLight: 'rgb(50, 50, 50)',
    textXLight: 'rgb(200, 200, 200)',
    placeholder: 'rgba(0, 0, 0, 0.3)',
    border: 'rgba(0, 0, 0, 0.06)',
    borderDark: 'rgba(0, 0, 0, 0.18)',
    // borderLight: 'rgba(0, 0, 0, 0.06)',
    borderLight: 'rgba(219, 219, 219, 1)',
    borderXLight: 'rgba(0, 0, 0, 0.12)',
    borderXXLight: 'rgba(0, 0, 0, 0.3)',
    loader: 'rgb(240, 240, 240)',
    toggle: 'white',
    toggleHover: 'white',
    toggleBorder: 'rgba(0, 0, 0, 0.1)',
    buttonText: 'white',
    opacity: 'rgba(0, 0, 0, 0.05)',
    opacityLight: 'rgba(0, 0, 0, 0.2)',
    buttonPrimary: {
      background: colors.primary,
      text: 'white',
      textDisabled: 'rgba(0, 0, 0, 0.5)',
    },
  },
};

const darkTheme: typeof lightTheme = {
  fontSize,
  appWidth: 1200,
  padding: 15,
  borderRadius: 9,
  progressBarColor: '#FFFFFF',
  colors: {
    ...colors,
    inverse: 'white',
    background: '#000000',
    backgroundTranslucent: 'rgba(0, 0, 0, 0.7)',
    foreground: 'rgb(40, 40, 40)',
    foregroundLight: 'rgb(20, 20, 20)',
    foregroundHover: 'rgb(50, 50, 50)',
    tagActive: 'white',
    tagActiveText: 'black',
    text: 'rgba(255, 255, 255, 0.9)',
    textLight: 'rgb(150, 150, 150)',
    textXLight: 'rgb(200, 200, 200)',
    placeholder: 'rgba(255, 255, 255, 0.3)',
    border: 'rgba(0, 0, 0, 0.2)',
    borderDark: 'rgba(0, 0, 0, 0.3)',
    borderLight: 'rgba(255, 255, 255, 0.06)',
    borderXLight: 'rgba(255, 255, 255, 0.12)',
    borderXXLight: 'rgba(255, 255, 255, 0.3)',
    loader: '#242424',
    toggle: '#242424',
    toggleHover: '#242424',
    toggleBorder: 'rgba(255, 255, 255, 0.1)',
    buttonText: 'white',
    opacity: 'rgba(255, 255, 255, 0.08)',
    opacityLight: 'rgba(255, 255, 255, 0.2)',
    buttonPrimary: {
      background: colors.primary,
      text: 'white',
      textDisabled: 'rgba(255, 255, 255, 0.5)',
    },
  },
};

export const themes = {
  [Theme.Light]: lightTheme,
  [Theme.Dark]: darkTheme,
};

export type ThemeType = typeof lightTheme | typeof darkTheme;
