import Link from 'next/link';
import styled from 'styled-components';
import Modal, { ModalHeader, ModalCloseButton, ModalBody, ModalProps } from '~/components/Modal';
import useOnNav from '~/hooks/useOnNav';
import { DropdownSeparator } from './Dropdown';
import Emoji from './Emoji';

const TextBold = styled.div`
  font-weight: bold;
`;

const AboutModal: React.FC<Omit<ModalProps, 'children'>> = ({
  isOpen,
  handleClose,
  keyPressEvents,
}) => {
  useOnNav(handleClose);
  return (
    <Modal { ...{ isOpen, handleClose, keyPressEvents } }>
      <ModalHeader>
        <h2 style={ { margin: 0 } }>
          <TextBold>WTF IS THIS HIC.AF THING???</TextBold>
        </h2>
        <ModalCloseButton onClick={ handleClose } />
      </ModalHeader>
      <ModalBody style={ { padding: 20 } }>
        As a creative coder and HEN lover, I built hic.af to empower artists and collectors.
        {' '}
        Feel free to
        {' '}
        <a
          href="https://twitter.com/gwendall"
          target="_blank"
          rel="noreferrer"
          style={ { fontWeight: 'bold' } }
        >
          follow me on Twitter
        </a>
        {' '}
        or
        {' '}
        <Link href="/gwendall/collection" passHref>
          <a style={ { fontWeight: 'bold' } }>check my HEN collection</a>
        </Link>
        .
        <DropdownSeparator style={ { marginTop: 15, marginBottom: 15, marginLeft: 0 } } />
        <div>
          <span style={ { marginRight: 5 } }>Gwendall</span>
          <span style={ { marginTop: 3 } }>
            <Emoji v="😘" />
          </span>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AboutModal;
