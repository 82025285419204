import { useRouter } from 'next/router';
import React from 'react';

const useOnNav = (cb) => {
  const router = useRouter();
  React.useEffect(() => {
    router.events.on('routeChangeStart', cb);
    return () => {
      router.events.off('routeChangeStart', cb);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useOnNav;
