/* eslint-disable @typescript-eslint/no-explicit-any */
import orderBy from 'lodash/orderBy';
import difference from 'lodash/difference';

import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import { CID } from 'multiformats/cid';
import { ActivityType, OperationType, User } from '~/types';
import { colors } from '~/styles/themes';

export const isBrowser = typeof window !== 'undefined';
// eslint-disable-next-line max-len
export const isMobile = isBrowser && /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent);
export const xtz = isMobile ? 'tez' : 'ꜩ';

export const shortenAddress = (address: string, length: number = 4): string => (
  address ? [address.slice(0, length), address.slice(-length)].join('...') : null);

export const tezosAddressRegex = /((?:tz1|tz2|tz3|KT1)[0-9a-zA-Z]{33})/g;
export const isTezosAddress = (str: string): boolean => tezosAddressRegex.test(str);

export const formatAmount = (balance: number, decimals?: number) => balance?.toLocaleString('en-US', {
  minimumFractionDigits: decimals,
  maximumFractionDigits: decimals,
}) || 0;

export const GATEWAY_PINATA = 'https://gateway.pinata.cloud/ipfs/';
export const GATEWAY_CLOUDFLARE = 'https://cloudflare-ipfs.com/ipfs/';
export const GATEWAY_IPFS = 'https://ipfs.io/ipfs/';
export const GATEWAY_INFURA = 'https://ipfs.infura.io/ipfs/';
export const GATEWAY_ALCHEMY = 'https://eth-mainnet.alchemyapi.io/v2/2xYvprNJC0LUdO8DJVL7VnMTTzzosoBQ/';

export const extractIPFSHash = (str: string): string => str?.match(/Qm[1-9A-HJ-NP-Za-km-z]{44,}/)?.[0];

export const getCid = (str: string): string => {
  const hash = extractIPFSHash(str);
  const cid = hash && CID.parse(hash);
  const v1 = cid && cid.toV1().toString();
  return v1;
};

// export const replaceIPFS = (str: string): string => str.replace('ipfs://', GATEWAY_CLOUDFLARE);
// export const replaceIPFS = (str: string): string => (getCid(str) ? `https://${getCid(str)}.ipfs.infura-ipfs.io/` : str);
export const replaceIPFS = (str: string): string => str?.replace('ipfs://', 'https://hic-af.infura-ipfs.io/ipfs/');
export const replaceIPFS2 = (str: string): string => str?.replace('ipfs://', GATEWAY_CLOUDFLARE);
export const replaceIPFSCustom = (str: string, baseUrl: string): string => str?.replace('ipfs://', baseUrl);

export const orderByFix = (array: any, orderKeys: string[], orderDirs: ('asc' | 'desc')[]) => {
  const ordered = orderBy(array, orderKeys, orderDirs);
  const withProp = ordered.filter((o) => o[orderKeys[0]]);
  const withoutProp = ordered.filter((o) => !o[orderKeys[0]]);
  return [...withProp, ...withoutProp];
};

export const includesArray = (a1: any[], a2: any[]) => difference(a2, a1).length === 0;

export const removeNullish = (o) => pickBy(o, identity);

/*
export const replaceQueryParams = (params, router) => {
  const value = {
    path: router.asPath.split('?')[0],
    query: removeNullish({
      ...params,
    }),
  };
  router.replace(value, value, { shallow: true });
};
*/

export const isServerReq = (req) => !req.url.startsWith('/_next');

export const filterNonNull = (o) => Object.fromEntries(Object.entries(o).filter(([, v]) => v));

export const operationEmojis: { [k in OperationType]?: string; } = {
  swap: '📰',
  cancel_swap: '❌',
  collect: '🤝',
  mint: '🎨',
  burn: '🔥',
};

export const activityEmojis: { [k in ActivityType]?: string; } = {
  listing: '📰',
  transfer: '✈️',
  buy: '⬅️',
  sell: '➡️',
  sale: '🤝',
  salePrimary: '🤝',
  saleSecondary: '🤝',
  mint: '🎨',
  burn: '🔥',
};

export const getUserName = (user: User) => user.name || shortenAddress(user.address);

export const getPerfColor = (perf: number) => (perf === 0 ? null
  : perf > 0 ? colors.success
    : perf < 0 ? colors.alert : null);

export const openFullscreen = (elem) => {
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.webkitRequestFullscreen) { /* Safari */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) { /* IE11 */
    elem.msRequestFullscreen();
  }
};
