import React from 'react';
import styled from 'styled-components';

const EmojiContainer = styled.span`
  &:hover {
    text-decoration: none;
  }
`;

const Emoji: React.FC<{
  v: string;
  className?: string;
  size?: number;
  style?: React.CSSProperties;
}> = ({ v, className, size, style }) => (
  <EmojiContainer
    className={ ['emoji', className].filter(Boolean).join(' ') }
    role="img"
    aria-label="emoji"
    style={
      {
        ...style,
        ...(size ? { fontSize: size } : {}),
      }
    }
  >
    {v}

  </EmojiContainer>
);

export default Emoji;
