import axios from 'axios';
import { useQuery } from 'react-query';

export const getBlockedWallets = (): Promise<string[]> => axios.get<string[]>(
  // 'https://raw.githubusercontent.com/hicetnunc2000/hicetnunc/main/filters/w.json',
  'https://raw.githubusercontent.com/hicetnunc2000/hicetnunc-reports/main/filters/w.json',
).then(({ data }) => data);

const useBlockedWallets = () => useQuery(
  ['blocked.wallets'],
  getBlockedWallets,
);

export default useBlockedWallets;
