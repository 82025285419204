import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import useOnClickOutside from '~/hooks/useOnClickOutside';
import useOnNav from '~/hooks/useOnNav';
import FlexRow from './FlexRow';

export const DropdownContainer2 = styled.div`
  border-color: ${({ theme }) => theme.colors.borderXLight};
  border-width: 1px;
  border-style: solid;
  background-color: ${({ theme }) => theme.colors.background};
  padding: 0;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 0;
  z-index: 1;
  width: 360px;
`;

export const DropdownContent = styled.div`
  padding: 15px;
`;

export const DropdownFooter = styled(FlexRow)`
  padding: 15px 20px;
  padding-bottom: 18px;
  border-top: ${({ theme }) => theme.colors.borderXLight} solid thin;
`;

const DropdownContainer = styled(motion.div)`
  position: absolute;
  bottom: 1px;
  right: 0;
  transform: translateY(100%);
  border: ${({ theme }) => theme.colors.borderXLight} solid thin;
  background-color: ${({ theme }) => theme.colors.background};
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  z-index: 1;
`;

export const DropdownItem = styled.div`
  user-select: none;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  width: 100%;
  cursor: pointer;
  padding: 5px 15px;
  transition: background 200ms ease;
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.opacity};
      text-decoration: none;
    }
  }
`;

export const DropdownSeparator = styled.div`
  width: 50px;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.borderXXLight};
  margin: 10px 16px;
`;

export const DropdownIcon = styled.div`
  width: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export type DropdownProps = {
  isOpen: boolean;
  handleClose: () => void;
  children: React.ReactChild | React.ReactChild[];
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  buttonRef: React.MutableRefObject<any>;
};

const dropdownVariant = {
  initial: {
    opacity: 0,
    transition: { duration: 0.15 },
  },
  isOpen: {
    opacity: 1,
    transition: { duration: 0.15 },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.15 },
  },
};

const Dropdown: React.FC<DropdownProps> = ({
  isOpen,
  handleClose,
  children,
  className,
  buttonRef,
}) => {
  const ref = React.useRef<HTMLDivElement>();
  useOnClickOutside(ref, buttonRef, handleClose);
  useOnNav(handleClose);
  return (
    <AnimatePresence>
      {
        isOpen ? (
          <DropdownContainer
            ref={ ref }
            key="dropdown"
            initial="initial"
            animate="isOpen"
            exit="exit"
            variants={ dropdownVariant }
            className={ className }
          >
            {children}
          </DropdownContainer>
        ) : null
      }
    </AnimatePresence>
  );
};

export default Dropdown;
