import styled from 'styled-components';
import Modal, { ModalHeader, ModalCloseButton, ModalBody, ModalProps } from '~/components/Modal';
import ThemeToggle from '~/components/ThemeToggle';
import CurrencySelect from '~/components/CurrencySelect';
import useHicetnuncContext from '~/contexts/Hicetnunc/useHicetnuncContext';
import Button from './Button';
import FlexRow from './FlexRow';
// import IndexerSelect from './IndexerSelect';

const TextBold = styled.div`
  font-weight: bold;
`;

const SettingsMenuItem = styled(FlexRow)`
  padding: 15px 20px;
  &:not(:last-of-type) {
    border-bottom: ${({ theme }) => theme.colors.borderXLight} solid thin;
  }
`;

const SettingsMenuLabel = styled.div`
  flex: 1;
`;

const SettingsMenuBody = styled.div``;

const SettingsMenu: React.FC<{
  sections: {
    label: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body: any;
  }[];
}> = ({
  sections = [],
}) => (
  <>
    {
      sections.map(({
        label,
        body,
      }) => (
        <SettingsMenuItem key={ `settings.${label}` }>
          <SettingsMenuLabel>{ label }</SettingsMenuLabel>
          <SettingsMenuBody>{ body }</SettingsMenuBody>
        </SettingsMenuItem>
      ))
    }
  </>
);

const SettingsModal: React.FC<Omit<ModalProps, 'children'>> = ({
  isOpen,
  handleClose,
  keyPressEvents,
}) => (
  <Modal { ...{ isOpen, handleClose, keyPressEvents } }>
    <ModalHeader>
      <h2 style={ { margin: 0 } }>
        <TextBold>Settings</TextBold>
      </h2>
      <ModalCloseButton onClick={ handleClose } />
    </ModalHeader>
    <ModalBody style={ { padding: 0 } }>
      <SettingsMenu sections={
        [
          {
            label: 'Theme',
            body: <ThemeToggle />,
          },
          {
            label: 'Convert prices to...',
            body: <CurrencySelect />,
          },
          /*
          {
            label: 'Choose your indexer...',
            body: <IndexerSelect />,
          },
          */
        ]
      }
      />
    </ModalBody>
  </Modal>
);
export default SettingsModal;

// <SignButton />
export const SignButton = () => {
  const {
    signIn,
  } = useHicetnuncContext();
  const handleSign = async () => {
    const signature = await signIn();
    console.log('Signed message.', signature);
  };
  return (<Button onClick={ handleSign }>Sign message</Button>);
};
